@import './vars.scss';
@import "~react-image-gallery/styles/scss/image-gallery.scss";

html,
body {
  margin: 0;
  padding: 0;
  overflow-y: overlay;
}

@keyframes Rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.suspense-fallback {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  &__icon {
    animation-name: Rotation;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
  }
}
